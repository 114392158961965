import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

interface IXUData {
  bars: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bars: [],
  requested: false,
  loaded: false,
};

export enum Period {
  OneMinute = "1",
  FiveMinutes = "5",
  FifteenMinutes = "15",
  SixtyMinutes = "60",
  Daily = "G",
}


export function loadBistSymbol(firebase: any, symbol: string, period: Period, count: number = 1000) {
  console.log(1)
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BistSymbol.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BIST_SYMBOL,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    console.log(2)
    firebase
      .firestore()
      .collection(`BIST_${symbol.toLocaleUpperCase()}`)
      .doc(period)
      .collection("bars")
      .orderBy("d", "desc")
      .limit(count)
      .onSnapshot((querySnapshot: any) => {
        var data: any[] = [];
        console.log(3)
        querySnapshot.forEach(function (doc: any) {
          const docData = doc.data();          
          data.push({
            d: moment(docData.d.toDate()).format("DD.MM.YY HH:mm"),
            o: docData.o,
            h: docData.h,
            l: docData.l,
            c: docData.c,
            a: docData.a,
            v: docData.v,
            s: docData.s,
          });
        });

        data.sort((a, b) => (a.d > b.d ? 1 : -1));

        console.log(data)
        dispatch({
          type: SENTIMENT_ACTION.BIST_SYMBOL,
          payload: {
            bars: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}


export default function BistSymbol(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BIST_SYMBOL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bistSymbolselector = (state: any) => state.BistSymbol;
