import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { indicesForPairSelect, symbolsViop } from "../../../util/ImkbSemboller";

const COLLECTION_NAME = "NS_HISTORY_LINES";

interface IXUData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadLinesHistory(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.LinesHistory.requested) return;

    dispatch({
      type: SENTIMENT_ACTION.LINES_HISTORY,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    const symbols = [...indicesForPairSelect, ...symbolsViop()]; 
    const chunkSize = 10; 
    const chunks = [];

    for (let i = 0; i < symbols.length; i += chunkSize) {
      chunks.push(symbols.slice(i, i + chunkSize)); 
    }

    const promises = chunks.map(chunk =>
      firebase
        .firestore()
        .collection(`${COLLECTION_NAME}`)
        .where("symbol", "in", chunk)
        .get()
    );

    try {
      const results = await Promise.all(promises); 
      const data: Object[] = [];

      const thresholdDate = "02.01.2024";

      results.forEach(querySnapshot => {
        querySnapshot.forEach((doc: any) => {
          const ix = doc.data().dateList.findIndex((date: string) => date === thresholdDate);
          data.push({
            Symbol: doc.data().symbol,
            Index: doc.data().index,
            SentimentList: doc.data().sentimentList.slice(ix),
            PriceList: doc.data().priceList.slice(ix),
            ChangeList: doc.data().changeList.slice(ix),
            DateList: doc.data().dateList.slice(ix),
            STP: doc.data().stpList.slice(ix),
            MTP: doc.data().mtpList.slice(ix),
            OrderList: doc.data().orderList.slice(ix),
            // SentimentList: doc.data().sentimentList,
            // PriceList: doc.data().priceList,
            // ChangeList: doc.data().changeList,
            // DateList: doc.data().dateList,
            // STP: doc.data().stpList,
            // MTP: doc.data().mtpList,
            // OrderList: doc.data().orderList,
            IsIndices: doc.data().symbol.startsWith("X"),
          });
        });
      });

      dispatch({
        type: SENTIMENT_ACTION.LINES_HISTORY,
        payload: {
          data: data,
          loaded: true,
          requested: true,
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch({
        type: SENTIMENT_ACTION.LINES_HISTORY,
        payload: { loaded: false, requested: false, error },
      });
    }
  };
}


export default function LinesHistory(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesHistorySelector = (state: any) => state.LinesHistory;
