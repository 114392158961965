import axios from "axios";
const key = "salgo_symbols_01012025";
export const setSymbols = () => {
    axios({
        url: 'https://europe-west1-algo-senti.cloudfunctions.net/zephlex-symbols',
        method: 'get',
        headers: {
            "x-api-key": "3ceb2ab173458a6e75df81c01dac15e461db173f6f4e9a63556221324b362afa",
        }
    })
        .then(({ data }) => {
            localStorage.setItem(key, JSON.stringify(data.symbols));
            console.log("SYMBOLS LOADED");
        })
        .catch(function (error) {
            console.log("SYMBOLS ERROR");
        });
};

const getSymbol = (ix) => {
    const symbols = JSON.parse(localStorage.getItem(key));

    if (symbols && symbols[ix]) {
        return symbols[ix].map((x) => { return x.Name });
    }
}

export const symbolsxHarz = () => {
    return [
        "ADGYO",
        "DMRGD",
        "DOFER",
        "HATSN",
        "MEKAG",
        "REEDR",
        "TABGD",
        "VRGYO",
    ]
};

export const symbolsViop = () => {
    return [
        "AEFES",
        "AKBNK",
        "AKSEN",
        "ALARK",
        "ARCLK",
        "ASELS",
        "ASTOR",
        "BIMAS",
        "BRSAN",
        "CIMSA",
        "DOAS",
        "DOHOL",
        "EKGYO",
        "ENJSA",
        "ENKAI",
        "EREGL",
        "FROTO",
        "GARAN",
        "GUBRF",
        "HALKB",
        "HEKTS",
        "ISCTR",
        "KCHOL",
        "KONTR",
        "KOZAA",
        "KOZAL",
        "KRDMD",
        "MGROS",
        "ODAS",
        "OYAKC",
        "PETKM",
        "PGSUS",
        "SAHOL",
        "SASA",
        "SISE",
        "SOKM",
        "TAVHL",
        "TCELL",
        "THYAO",
        "TKFEN",
        "TOASO",
        "TSKB",
        "TTKOM",
        "TUPRS",
        "VAKBN",
        "VESTL",
        "YKBNK",
        "ULKER",
    ]
};

export const indicesForPair = () => {
    return [
        { name: "Bankacılık", items: "AKBNK,YKBNK,GARAN,HALKB,VAKBN,TSKB,ISCTR" },
        { name: "Demir-Çelik", items: "EREGL,KRDMD" },
        { name: "Enerji", items: "ASTOR,KONTR,ODAS,AKSEN,ENJSA" },
        { name: "Holding", items: "SAHOL,DOHOL,KCHOL" },
        { name: "Madencilik", items: "KOZAA,KOZAL" },
        { name: "Perakende", items: "MGROS,SOKM,BIMAS,ULKER" },
        { name: "Sanayi", items: "EREGL,KRDMD,SISE,TUPRS,PETKM" },
        { name: "Ulaştırma", items: "THYAO,TAVHL,PGSUS" },
    ];

};

export const indicesForPairSelect = [
    "XAKUR",
    "XBANK",
    "XBLSM",
    "XELKT",
    "XFINK",
    "XGMYO",
    "XGIDA",
    "XUHIZ",
    "XHOLD",
    "XILTM",
    "XINSA",
    "XK100",
    "XKTUM",
    "XKMYA",
    "XMADN",
    "XMANA",
    "XMESY",
    "XKAGT",
    "XSGRT",
    "XUSIN",
    "XSPOR",
    "XTAST",
    "XUTEK",
    "XTCRT",
    "XTRZM",
    "XULAS",
    "XYLDZ",
];


export const symbols030 = () => { return getSymbol("x030"); }
export const symbols070 = () => { return getSymbol("x100"); }
export const symbols200 = () => { return getSymbol("x200"); }
export const symbols400 = () => { return getSymbol("x400"); }

export const symbols030_weekly = () => { return getSymbol("x030_weekly"); }
export const symbols070_weekly = () => { return getSymbol("x070_weekly"); }
export const symbols200_weekly = () => { return getSymbol("x200_weekly"); }
export const symbols400_weekly = () => { return getSymbol("x400_weekly"); }

